<template>
  <!--begin::Dashboard-->
  <div>
    <div class="row">
      <div v-show="lista_permissoes_filial.ra_Usuario" class="col-xxl-4 mt-5">
        <a
          @click="trocar_pagina('gerenFuncionarios')"
          class="card card-custom wave wave-animate-fast wave-success"
        >
          <div class="card-body">
            <div class="d-flex align-items-cente">
              <div class="mr-6">
                <span class="svg-icon svg-icon-success svg-icon-4x">
                  <svg>...</svg>
                </span>
              </div>
              <div class="d-flex flex-column">
                <a
                  @click="trocar_pagina('gerenFuncionarios')"
                  class="
                    text-dark text-hover-danger
                    font-weight-bold font-size-h4
                    mb-3
                  "
                >
                  Funcionarios
                </a>
                <div class="text-dark-75">
                  Aqui pode visualizar Funcionarios
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
    <div class="row">
      <div
        v-show="lista_permissoes_filial.c_Usuario"
        class="col-lg-6 col-md-6 col-12 mt-5"
      >
        <v-card class="mx-auto text-center" color="blue " dark max-width="600">
          <v-card-text>
            <v-sheet color="rgba(0, 0, 0, .12)">
              <v-sparkline
                :value="value"
                color="rgba(255, 255, 255, .7)"
                height="100"
                padding="24"
                stroke-linecap="round"
                smooth
              >
                <template v-slot:label="item">
                 {{item.value  | formatMoney }}
                </template>
              </v-sparkline>
            </v-sheet>
          </v-card-text>

          <v-card-text>
            <div class="text-h4 font-weight-thin">Ultimas 24H</div>
          </v-card-text>

          <v-divider></v-divider>
          <!-- 
  
        <v-card-actions class="justify-center">
          <v-btn block text> Go to Report </v-btn>
        </v-card-actions> -->
        </v-card>
      </div>

      <div id="chart" class="col-lg-6 col-md-6 col-12 mt-5">
        <apexchart
          type="donut"
          width="580"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </div>
    </div>
  </div>
  <!--end::Dashboard-->
</template>

<script>
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import moneyFormat from "@/core/mixins/moneyFormat";


export default {
  name: "dashboard",
  mixins: [moneyFormat],

  data() {
    return {
      value: [423, 446, 675, 510, 590, 610],
      series: [44, 55, 41, 17],
      chartOptions: {
        chart: {
          width: 380,
          type: "donut",
        },
        labels: [ 'Saques Aprovados', 'Depositos Aprovados', 'Saques Aguardando','Depositos Aguardando'],
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 270,
          },
        },
        dataLabels: {
          enabled: false,
        },
        fill: {
          type: "gradient",
        },
        legend: {
          formatter: function (val, opts) {
            return val + " - " + opts.w.globals.series[opts.seriesIndex];
          },
        },
        title: {
          text: "SAQUE E DEPOSITOS",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 300,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
  },

  computed: {
    lista_permissoes_filial() {
      return this.$store.state.sistema.lista_permissoes_filial;
    },
  },
  created() {
    this.$store.dispatch("contas/listar_contas");
  },
  methods: {
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    trocar_pagina(rota) {
      this.$router.push({ name: rota });
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    },
  },
};
</script>
